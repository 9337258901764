import { createStructuredSelector } from 'reselect'

import {
  SEARCH_GEO_COUNTRY,
  SEARCH_GEO_COUNTRY_SUCCESS,
  SEARCH_GEO_COUNTRY_ERROR,
  SEARCH_ENTERPRISE_TYPE,
  SEARCH_ENTERPRISE_TYPE_SUCCESS,
  SEARCH_ENTERPRISE_TYPE_ERROR,
  SEARCH_PRODUCT_TYPE,
  SEARCH_PRODUCT_TYPE_SUCCESS,
  SEARCH_PRODUCT_TYPE_ERROR,
  SEARCH_DELIVERY_TERM,
  SEARCH_DELIVERY_TERM_SUCCESS,
  SEARCH_DELIVERY_TERM_ERROR,
  SEARCH_ARTICLE_GROUP,
  SEARCH_ARTICLE_GROUP_SUCCESS,
  SEARCH_ARTICLE_GROUP_ERROR,
  SEARCH_CURRENCY,
  SEARCH_CURRENCY_SUCCESS,
  SEARCH_CURRENCY_ERROR,
  SEARCH_QUOTE_EXPIRATION,
  SEARCH_QUOTE_EXPIRATION_SUCCESS,
  SEARCH_QUOTE_EXPIRATION_ERROR,
  SEARCH_INCOTERM,
  SEARCH_INCOTERM_SUCCESS,
  SEARCH_INCOTERM_ERROR,
  SEARCH_TRANSPORT_TYPE,
  SEARCH_TRANSPORT_TYPE_SUCCESS,
  SEARCH_TRANSPORT_TYPE_ERROR,
  SEARCH_FILE_TYPE,
  SEARCH_FILE_TYPE_SUCCESS,
  SEARCH_FILE_TYPE_ERROR,
  SEARCH_REQUEST_PROCESS_TYPE,
  SEARCH_REQUEST_PROCESS_TYPE_SUCCESS,
  SEARCH_REQUEST_PROCESS_TYPE_ERROR,
  SEARCH_REQUEST_ARTICLE_TYPE,
  SEARCH_REQUEST_ARTICLE_TYPE_SUCCESS,
  SEARCH_REQUEST_ARTICLE_TYPE_ERROR,
  SEARCH_HEALTH_REGISTRY_AGENCY,
  SEARCH_HEALTH_REGISTRY_AGENCY_SUCCESS,
  SEARCH_HEALTH_REGISTRY_AGENCY_ERROR,
  SEARCH_BMP_AGENCY,
  SEARCH_BMP_AGENCY_SUCCESS,
  SEARCH_BMP_AGENCY_ERROR,
} from '../actions';

export const NAME = 'search';


export const SearchGeoCountry = (data) => ({
  type: SEARCH_GEO_COUNTRY,
  payload: data
});

export const SearchGeoCountrySuccess = (data) => ({
  type: SEARCH_GEO_COUNTRY_SUCCESS,
  payload: data
});

export const SearchGeoCountryError = (error) => ({
  type: SEARCH_GEO_COUNTRY_ERROR,
  payload: error
});

export const SearchEnterpriseType = (data) => ({
  type: SEARCH_ENTERPRISE_TYPE,
  payload: data
});

export const SearchEnterpriseTypeSuccess = (data) => ({
  type: SEARCH_ENTERPRISE_TYPE_SUCCESS,
  payload: data
});

export const SearchEnterpriseTypeError = (error) => ({
  type: SEARCH_ENTERPRISE_TYPE_ERROR,
  payload: error
});

export const SearchProductType = (filters) => ({
  type: SEARCH_PRODUCT_TYPE,
  payload: {filters: filters}
}); 

export const SearchProductTypeSuccess = (data) => ({
  type: SEARCH_PRODUCT_TYPE_SUCCESS,
  payload: data
});

export const SearchProductTypeError = (error) => ({
  type: SEARCH_PRODUCT_TYPE_ERROR,
  payload: error
});

export const SearchDeliveryTerm = () => ({
  type: SEARCH_DELIVERY_TERM
});

export const SearchDeliveryTermSuccess = (data) => ({
  type: SEARCH_DELIVERY_TERM_SUCCESS,
  payload: data
});

export const SearchDeliveryTermError = (error) => ({
  type: SEARCH_DELIVERY_TERM_ERROR,
  payload: error
});

export const SearchCurrency = () => ({
  type: SEARCH_CURRENCY
});

export const SearchCurrencySuccess = (data) => ({
  type: SEARCH_CURRENCY_SUCCESS,
  payload: data
});

export const SearchCurrencyError = (error) => ({
  type: SEARCH_CURRENCY_ERROR,
  payload: error
});

export const SearchArticleGroup = () => ({
  type: SEARCH_ARTICLE_GROUP
});

export const SearchArticleGroupSuccess = (data) => ({
  type: SEARCH_ARTICLE_GROUP_SUCCESS,
  payload: data
});

export const SearchArticleGroupError = (error) => ({
  type: SEARCH_ARTICLE_GROUP_ERROR,
  payload: error
});

export const SearchQuoteExpiration = () => ({
  type: SEARCH_QUOTE_EXPIRATION
});

export const SearchQuoteExpirationSuccess = (data) => ({
  type: SEARCH_QUOTE_EXPIRATION_SUCCESS,
  payload: data
});

export const SearchQuoteExpirationError = (error) => ({
  type: SEARCH_QUOTE_EXPIRATION_ERROR,
  payload: error
});

export const SearchIncoterm = () => ({
  type: SEARCH_INCOTERM
});

export const SearchIncotermSuccess = (data) => ({
  type: SEARCH_INCOTERM_SUCCESS,
  payload: data
});

export const SearchIncotermError = (error) => ({
  type: SEARCH_INCOTERM_ERROR,
  payload: error
});

export const SearchTransportType = () => ({
  type: SEARCH_TRANSPORT_TYPE
});

export const SearchTransportTypeSuccess = (data) => ({
  type: SEARCH_TRANSPORT_TYPE_SUCCESS,
  payload: data
});

export const SearchTransportTypeError = (error) => ({
  type: SEARCH_TRANSPORT_TYPE_ERROR,
  payload: error
});

export const SearchFileType = (data) => ({
  type: SEARCH_FILE_TYPE,
  payload: data
});

export const SearchFileTypeSuccess = (data) => ({
  type: SEARCH_FILE_TYPE_SUCCESS,
  payload: data
});

export const SearchFileTypeError = (error) => ({
  type: SEARCH_FILE_TYPE_ERROR,
  payload: error
});

export const SearchRequestProcessType = (data) => ({
  type: SEARCH_REQUEST_PROCESS_TYPE,
  payload: data
});

export const SearchRequestProcessTypeSuccess = (data) => ({
  type: SEARCH_REQUEST_PROCESS_TYPE_SUCCESS,
  payload: data
});

export const SearchRequestProcessTypeError = (error) => ({
  type: SEARCH_REQUEST_PROCESS_TYPE_ERROR,
  payload: error
});

export const SearchRequestArticleType = (data) => ({
  type: SEARCH_REQUEST_ARTICLE_TYPE,
  payload: data
});

export const SearchRequestArticleTypeSuccess = (data) => ({
  type: SEARCH_REQUEST_ARTICLE_TYPE_SUCCESS,
  payload: data
});

export const SearchRequestArticleTypeError = (error) => ({
  type: SEARCH_REQUEST_ARTICLE_TYPE_ERROR,
  payload: error
});

export const SearchHealthRegistryAgency = (data) => ({
  type: SEARCH_HEALTH_REGISTRY_AGENCY,
  payload: data
});

export const SearchHealthRegistryAgencySuccess = (data) => ({
  type: SEARCH_HEALTH_REGISTRY_AGENCY_SUCCESS,
  payload: data
});

export const SearchHealthRegistryAgencyError = (error) => ({
  type: SEARCH_HEALTH_REGISTRY_AGENCY_ERROR,
  payload: error
});

export const SearchBmpAgency = (data) => ({
  type: SEARCH_BMP_AGENCY,
  payload: data
});

export const SearchBmpAgencySuccess = (data) => ({
  type: SEARCH_BMP_AGENCY_SUCCESS,
  payload: data
});

export const SearchBmpAgencyError = (error) => ({
  type: SEARCH_BMP_AGENCY_ERROR,
  payload: error
});

const search = (state) => state[NAME];

export const SearchSelector = createStructuredSelector({
  search
})
