import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  SEARCH_GEO_COUNTRY,
  SEARCH_ENTERPRISE_TYPE,
  SEARCH_PRODUCT_TYPE,
  SEARCH_DELIVERY_TERM,
  SEARCH_ARTICLE_GROUP,
  SEARCH_CURRENCY,
  SEARCH_QUOTE_EXPIRATION,
  SEARCH_INCOTERM,
  SEARCH_TRANSPORT_TYPE,
  SEARCH_FILE_TYPE,
  SEARCH_REQUEST_PROCESS_TYPE,
  SEARCH_REQUEST_ARTICLE_TYPE,
  SEARCH_HEALTH_REGISTRY_AGENCY,
  SEARCH_BMP_AGENCY
} from '../actions';

import {
  SearchGeoCountrySuccess,
  SearchGeoCountryError,
  SearchEnterpriseTypeSuccess,
  SearchEnterpriseTypeError,
  SearchProductTypeSuccess,
  SearchProductTypeError,
  SearchDeliveryTermSuccess,
  SearchDeliveryTermError,
  SearchArticleGroupSuccess,
  SearchArticleGroupError,
  SearchCurrencySuccess,
  SearchCurrencyError,
  SearchQuoteExpirationSuccess,
  SearchQuoteExpirationError,
  SearchIncotermSuccess,
  SearchIncotermError,
  SearchTransportTypeSuccess,
  SearchTransportTypeError,
  SearchFileTypeSuccess,
  SearchFileTypeError,
  SearchRequestProcessTypeSuccess,
  SearchRequestProcessTypeError,
  SearchRequestArticleTypeSuccess,
  SearchRequestArticleTypeError,
  SearchHealthRegistryAgencySuccess,
  SearchHealthRegistryAgencyError,
  SearchBmpAgencySuccess,
  SearchBmpAgencyError
} from '../actions';


export function* watchSearchGeoCountry() {
  yield takeEvery(SEARCH_GEO_COUNTRY, onSearchGeoCountry);
}

export function* watchSearchEnterpriseType() {
  yield takeEvery(SEARCH_ENTERPRISE_TYPE, onSearchEnterpriseType);
}

export function* watchSearchProductType() {
  yield takeEvery(SEARCH_PRODUCT_TYPE, onSearchProductType);
}

export function* watchSearchDeliveryTerm() {
  yield takeEvery(SEARCH_DELIVERY_TERM, onSearchDeliveryTerm);
}

export function* watchSearchArticleGroup() {
  yield takeEvery(SEARCH_ARTICLE_GROUP, onSearchArticleGroup);
}

export function* watchSearchCurrency() {
  yield takeEvery(SEARCH_CURRENCY, onSearchCurrency);
}

export function* watchSearchQuoteExpiration() {
  yield takeEvery(SEARCH_QUOTE_EXPIRATION, onSearchQuoteExpiration);
}

export function* watchSearchIncoterm() {
  yield takeEvery(SEARCH_INCOTERM, onSearchIncoterm);
}

export function* watchSearchTransportType() {
  yield takeEvery(SEARCH_TRANSPORT_TYPE, onSearchTransportType);
}

export function* watchSearchFileType() {
  yield takeEvery(SEARCH_FILE_TYPE, onSearchFileType);
}

export function* watchSearchRequestProcessType() {
  yield takeEvery(SEARCH_REQUEST_PROCESS_TYPE, onSearchRequestProcessType);
}

export function* watchSearchRequestArticleType() {
  yield takeEvery(SEARCH_REQUEST_ARTICLE_TYPE, onSearchRequestArticleType);
}

export function* watchSearchHealthRegistryAgency() {
  yield takeEvery(SEARCH_HEALTH_REGISTRY_AGENCY, onSearchHealthRegistryAgency);
}

export function* watchSearchBmpAgency() {
  yield takeEvery(SEARCH_BMP_AGENCY, onSearchBmpAgency);
}

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onSearchGeoCountryAsync = async (payload) => { 
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchEnterpriseTypeAsync = async (payload) => {
  const response = await fetch(servicePath + 'search/enterprise_type', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchProductTypeAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/product_type', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};


const onSearchDeliveryTermAsync = async () => {
  const response = await fetch(servicePath + 'search/delivery_term', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchArticleGroupAsync = async () => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/article_group', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token }
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchCurrencyAsync = async () => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/currency', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token }
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchQuoteExpirationAsync = async () => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/quote_expiration', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token }
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchIncotermAsync = async () => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/incoterm', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token }
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchTransportTypeAsync = async () => {
  const response = await fetch(servicePath + 'search/transport_type', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json'}
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchFileTypeAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/file_type', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchRequestProcessTypeAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/request_process_type', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchRequestArticleTypeAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/request_article_type', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchHealthRegistryAgencyAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/health_registry_agency', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSearchBmpAgencyAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/bmp_agency', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

function* onSearchGeoCountry(data) {
  try {
    const response = yield call(onSearchGeoCountryAsync, data.payload);
    yield put(SearchGeoCountrySuccess(response.data));
  } catch (error) {
    yield put(SearchGeoCountryError(error));
  }
}

function* onSearchEnterpriseType(data) {
  try {
    const response = yield call(onSearchEnterpriseTypeAsync, data.payload);
    yield put(SearchEnterpriseTypeSuccess(response.data));
  } catch (error) {
    yield put(SearchEnterpriseTypeError(error));
  }
}

function* onSearchProductType(data) { 
  try {
    const response = yield call(onSearchProductTypeAsync, data.payload);
    yield put(SearchProductTypeSuccess(response.data));
  } catch (error) {
    yield put(SearchProductTypeError(error));
  }
}

function* onSearchDeliveryTerm(data) {
  try {
    const response = yield call(onSearchDeliveryTermAsync, data);
    yield put(SearchDeliveryTermSuccess(response.data));
  } catch (error) {
    yield put(SearchDeliveryTermError(error));
  }
}

function* onSearchArticleGroup(data) {
  try {
    const response = yield call(onSearchArticleGroupAsync, data);
    yield put(SearchArticleGroupSuccess(response.data));
  } catch (error) {
    yield put(SearchArticleGroupError(error));
  }
}

function* onSearchCurrency(data) {
  try {
    const response = yield call(onSearchCurrencyAsync, data);
    yield put(SearchCurrencySuccess(response.data));
  } catch (error) {
    yield put(SearchCurrencyError(error));
  }
}

function* onSearchQuoteExpiration(data) {
  try {
    const response = yield call(onSearchQuoteExpirationAsync, data);
    yield put(SearchQuoteExpirationSuccess(response.data));
  } catch (error) {
    yield put(SearchQuoteExpirationError(error));
  }
}

function* onSearchIncoterm(data) {
  try {
    const response = yield call(onSearchIncotermAsync, data);
    yield put(SearchIncotermSuccess(response.data));
  } catch (error) {
    yield put(SearchIncotermError(error));
  }
}

function* onSearchTransportType(data) {
  try {
    const response = yield call(onSearchTransportTypeAsync, data);
    yield put(SearchTransportTypeSuccess(response.data));
  } catch (error) {
    yield put(SearchTransportTypeError(error));
  }
}

function* onSearchFileType(data) {
  try {
    const response = yield call(onSearchFileTypeAsync, data.payload);
    yield put(SearchFileTypeSuccess(response.data));
  } catch (error) {
    yield put(SearchFileTypeError(error));
  }
}

function* onSearchRequestProcessType(data) {
  try {
    const response = yield call(onSearchRequestProcessTypeAsync, data.payload);
    yield put(SearchRequestProcessTypeSuccess(response.data));
  } catch (error) {
    yield put(SearchRequestProcessTypeError(error));
  }
}

function* onSearchRequestArticleType(data) {
  try {
    const response = yield call(onSearchRequestArticleTypeAsync, data.payload);
    yield put(SearchRequestArticleTypeSuccess(response.data));
  } catch (error) {
    yield put(SearchRequestArticleTypeError(error));
  }
}

function* onSearchHealthRegistryAgency(data) {
  try {
    const response = yield call(onSearchHealthRegistryAgencyAsync, data.payload);
    yield put(SearchHealthRegistryAgencySuccess(response.data));
  } catch (error) {
    yield put(SearchHealthRegistryAgencyError(error));
  }
}


function* onSearchBmpAgency(data) {
  try {
    const response = yield call(onSearchBmpAgencyAsync, data.payload);
    yield put(SearchBmpAgencySuccess(response.data));
  } catch (error) {
    yield put(SearchBmpAgencyError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchSearchGeoCountry),
    fork(watchSearchEnterpriseType),
    fork(watchSearchProductType),
    fork(watchSearchDeliveryTerm),
    fork(watchSearchArticleGroup),
    fork(watchSearchCurrency),
    fork(watchSearchQuoteExpiration),
    fork(watchSearchIncoterm),
    fork(watchSearchTransportType),
    fork(watchSearchFileType),
    fork(watchSearchRequestProcessType),
    fork(watchSearchRequestArticleType),
    fork(watchSearchHealthRegistryAgency),
    fork(watchSearchBmpAgency),
  ]);
}